.manage {
    width: 100%;
    display: flex;
    margin-top: 3vmax;
    flex-direction: row;
    flex-wrap: wrap;
}

.manage-flex {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vmax;
}

.manage-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}
