.login-page {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-input {
    text-align: center;
    padding: 5px;
    font-size: 16px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -webkit-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -moz-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    min-width: 14vmax;
    margin-bottom: 1vmax;
    border-radius: 3px;

}

.login-button {
    background-color: #28AF63;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 20px;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}
.login-button:hover {
    background-color: #2d8a3d;
}

.login-logo {
    height: auto;
    width: 15vmin;
    align-self: center;
}