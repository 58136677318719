.input-style {
    width: 100%;
    background-color: #F7F7F7;
    text-align: center;
    resize: vertical;
}

.input-style:active {
    border: 1px solid #D9D9D9 !important;
}

.input-file {
    height: 100%;
    opacity: 0;
    position: sticky;
    width: auto;
    z-index: 3;
    cursor: pointer;
}

.input-file-img {
    float: left;
    position: absolute;
    max-width: 42px;
}

.onoffswitch {
    position: relative; width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 36px; padding: 0; line-height: 36px;
    border: 2px solid #E3E3E3; border-radius: 36px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 36px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 22px;
    border: 2px solid #E3E3E3; border-radius: 36px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}