.ContentContainer {
    display: flex;
    background-color: white;
}

.PopupContainer {
    display: flex;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: 20%;
    left: 0;
}

.hidden {
    display: none !important;
}

.PopupBorder {
    background-color: #F7F7F7;
    min-height: 20vmax;
    min-width: 85vmax;
    transform: translate(0%, -30%);
    box-shadow: 0px 2px 14px #000000;
    border: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    margin: 5vmin;
}

.PopupClose {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 3vmin;
    margin-right: 3vmin;
    font-size: 5vmin;
    cursor: pointer;
}

.PopupButton {
    align-self: flex-end;
    margin-top: 11vmin;
    margin-bottom: 4vmin;
}