.nrecipe-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.nrecipe-header {
    display: flex;
    flex-direction: row;
    margin-left: 4vmax;
}

.nrecipe-form-container {
    display: flex;
    flex-direction: column;
    margin-left: 4vmax;
    margin-right: 4vmax;
}

.nrecipe-form-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nrecipe-form-left {

}

.nrecipe-form-right {
    width: 50%;
    display: flex;
}

.list-container > div {
    list-style: none;
    padding-bottom: 1vmin;
    color: #1A2828;
}

.list-container > div::before {
    content: "• ";
    color: #2d8a3d;
}

.list-container {
    text-align: left;
    height: 50%;
    margin-top: 2vmin;
}

.mid-large {
    width: 55%;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

.form-description-container {
    display: flex;
    flex-direction: column;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 7px #555555;
    border: 1px solid #D9D9D9;
    margin-bottom: 1vmax;
    margin-top: 2vmax;
    padding: 1vmax;
}

.form-description-label {
    align-self: flex-start;
    margin-top: 0;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
    align-items: center;
}

.form-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-img {
    border-radius: 5px;
    margin-top: 2vmin;
    max-width: 20vmin;
}

.input-recipe-label {
    margin: 0;
}

.input-recipe-header {
    margin-left: 2vmin;
    text-align: center;
}

.input-recipe-text {
    resize: none;
    min-height: 10vmax!important;
}

.input-recipe {
    border: 1px solid #DDDDDD;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    border-radius: 5px;
    min-height: 25px;
}

.input-recipe:focus {
    box-shadow: 0 0 5px #28AF63;
    border: 1px solid #28AF63;
}

.button-recipe {
    align-self: flex-end;
    margin-top: 5vmin;
    margin-bottom: 2vmin;
}

.onoffswitch {
    position: relative; width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 25px; padding: 0; line-height: 25px;
    border: 2px solid #E3E3E3; border-radius: 25px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 25px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 33px;
    border: 2px solid #E3E3E3; border-radius: 25px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}
