.dashboard {
    width: 100%;
}

.dashboard-content {
    margin-top: 10vmin;
    display: flex;
    justify-content: space-around;
}

.dashboard-button {
    background-color: #28AF63;
    font-family: 'Arvo', serif;
    color: #f7f7f7;
    min-width: 20vmax;
    min-height: 12vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
}

.dashboard-button:hover {
    background-color: #2d8a3d;
}

.button-count {
    font-size: 47px;
}

.button-what {
    font-family: 'Lato', serif;
    font-size: 36px;
}

.button-news {
    font-family: 'Lato', serif;
    font-size: 36px;
    color: #FF635C;
}

.button-link {
    text-decoration: none;
    color: #F7F7F7;
    width: 100%;
}