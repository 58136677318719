
.loading-container {
    width: 100%;
    height: 90%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: white;
}

.loading-logo {
    animation: Loading-logo infinite 3s linear;
}

@keyframes Loading-logo {
    from {
        transform: rotateY(0deg);
    } to {
        transform: rotateY(360deg);
    }
}