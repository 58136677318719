.dheader-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10vmin;
    margin-left: 5vmax;
    margin-right: 5vmax;
}

.dheader-label {
    margin-top: 0;
    margin-bottom: 0;
    padding: 1.5vmin;
}

h1 {
    font-size: 1.5em;
}

.dheader-button {
    margin-right: 2rem;
    background-color: #28AF63;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 20px;
    padding: 1rem 2rem;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}

.dheader-button:hover {
    background-color: #2d8a3d;
}

.input-search {
    padding: 5px;
    font-size: 16px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 1px;
    text-align: right;
    box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -webkit-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -moz-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    min-width: 14vmax;
}

.input-search:focus {
    outline: none;
}

.right {
    margin-left: auto;
}

.dheader-button-disabled {
    background-color: #5a9c77 !important;
    cursor: default !important;
}
