.navbar-content {
    display: flex;
    justify-content: space-between;
}

.navbar-logo {
    height: 18vmin;
    width: 18vmin;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-link {
    display: flex;
    align-items: center;
}

.nav {
    list-style: none;
    display: flex;
    justify-content: space-around;
    font-family: 'Arvo', serif;
    font-size: 22px;
}

.nav-logo {
    height: 10vmin;
}

.nav-link {
    margin-right: 5vmin;
}

nav {
    position: relative;
    box-shadow: 0px 3px 10px 0px rgba(72,72,72,0.6);
    -webkit-box-shadow: 0px 3px 10px 0px rgba(72,72,72,0.6);
    -moz-box-shadow: 0px 3px 10px 0px rgba(72,72,72,0.6);
    background-color: #f7f7f7;
}

.nav-active {
    border-bottom: 4px solid #28AF63;
}

.nav-notif::after {
    content: "•";
    position: relative;
    top: -5px;
    color: #FF635C;
}

.nav-a {
    color: #1A2828;
    text-decoration: none;
}