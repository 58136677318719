@import url(https://fonts.googleapis.com/css?family=Arvo:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dashboard {
    width: 100%;
}

.dashboard-content {
    margin-top: 10vmin;
    display: flex;
    justify-content: space-around;
}

.dashboard-button {
    background-color: #28AF63;
    font-family: 'Arvo', serif;
    color: #f7f7f7;
    min-width: 20vmax;
    min-height: 12vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
}

.dashboard-button:hover {
    background-color: #2d8a3d;
}

.button-count {
    font-size: 47px;
}

.button-what {
    font-family: 'Lato', serif;
    font-size: 36px;
}

.button-news {
    font-family: 'Lato', serif;
    font-size: 36px;
    color: #FF635C;
}

.button-link {
    text-decoration: none;
    color: #F7F7F7;
    width: 100%;
}

.loading-container {
    width: 100%;
    height: 90%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: white;
}

.loading-logo {
    -webkit-animation: Loading-logo infinite 3s linear;
            animation: Loading-logo infinite 3s linear;
}

@-webkit-keyframes Loading-logo {
    from {
        transform: rotateY(0deg);
    } to {
        transform: rotateY(360deg);
    }
}

@keyframes Loading-logo {
    from {
        transform: rotateY(0deg);
    } to {
        transform: rotateY(360deg);
    }
}
.ContentContainer {
    display: flex;
    background-color: white;
}

.PopupContainer {
    display: flex;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: 20%;
    left: 0;
}

.hidden {
    display: none !important;
}

.PopupBorder {
    background-color: #F7F7F7;
    min-height: 20vmax;
    min-width: 85vmax;
    transform: translate(0%, -30%);
    box-shadow: 0px 2px 14px #000000;
    border: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    margin: 5vmin;
}

.PopupClose {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 3vmin;
    margin-right: 3vmin;
    font-size: 5vmin;
    cursor: pointer;
}

.PopupButton {
    align-self: flex-end;
    margin-top: 11vmin;
    margin-bottom: 4vmin;
}
.dheader-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10vmin;
    margin-left: 5vmax;
    margin-right: 5vmax;
}

.dheader-label {
    margin-top: 0;
    margin-bottom: 0;
    padding: 1.5vmin;
}

h1 {
    font-size: 1.5em;
}

.dheader-button {
    margin-right: 2rem;
    background-color: #28AF63;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 20px;
    padding: 1rem 2rem;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}

.dheader-button:hover {
    background-color: #2d8a3d;
}

.input-search {
    padding: 5px;
    font-size: 16px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 1px;
    text-align: right;
    box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -webkit-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -moz-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    min-width: 14vmax;
}

.input-search:focus {
    outline: none;
}

.right {
    margin-left: auto;
}

.dheader-button-disabled {
    background-color: #5a9c77 !important;
    cursor: default !important;
}

.dash-table {
    display: flex;
    justify-content: space-evenly;
}

.dash-table-header {
    background-color: #28AF63;
    display: flex;
    justify-content: space-evenly;
    color: #F7F7F7;
    -webkit-border-top-left-radius: .9vmin;
    -webkit-border-top-right-radius: .9vmin;
    -moz-border-radius-topleft: .9vmin;
    -moz-border-radius-topright: .9vmin;
    border-top-left-radius:.9vmin;
    border-top-right-radius:.9vmin;
}

.dash-table-row {
    background-color: #F7F7F7;
    display: flex;
    justify-content: space-evenly;
    color: black;
    width: 100%;
}

.table {
    margin: 5vmax;
    border-color: #D9D9D9;
}

.dash-table-label {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    width: 100%;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 5 1;
}

.dash-table-cell {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    width: 100%;
    border: 1px solid #D9D9D9 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 5 1;
}

.dash-table-cell-qty {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    width: 100%;
    border: 1px solid #D9D9D9 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 15 1;
}

.dash-table-multiple {
    justify-content: space-around !important;
}

.dash-table-cell-reduce {
    flex: 3 1;
}

.dash-table-cell-expand {
    flex: 8 1;
}

.dash-table-cell-content {
    font-size: .7vw;
}

.table-cell-img > img {
    max-width: 50%;
    max-height: 20vh;
    border-radius: 10px;
}

.dash-table-empty {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    flex: 1 1;
    border: .05vmin solid #D9D9D9;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.cell-edit {
    cursor: pointer;
    width: 60%;
}

.dash-table-reduce {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3 1;
}

.dash-table-quantity {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 15 1;
    width: 100%;
}

.dash-table-expand {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 8 1;
    width: 100%;
}

.dash-table-button {
    color: #FD0B00;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-family: 'Nunito', serif;
    font-size: 20px;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
}

.dash-table-button:hover {
    color: #9f0900;
}

.dash-table-validate {
    color: #28AF63!important;
}

.dash-table-validate:hover {
    color: #144b25!important;
}

.dash-table-container {
    width: 100%;
    height: 100%;
}

.dash-table-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.promotion-label {
    font-size: .7vw;
    width: 50%;
    line-height: 15px;
    cursor: pointer;
}

.promotion-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
}

.input-money::after {
    content: "€";
    margin-top: 1vmin;
    font-size: smaller;
}

.promotion-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}

.promotion-button-input {
    border-radius: 5px;
    background-color: #28AF63;
    color: #F7F7F7;
    border: none;
    box-shadow: 0px 1px 7px #000000;
    cursor: pointer;
}

.promotion-button-disabled {
    cursor: default;
}

.promotion-display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-top: 2vmin;
}

.promotion-input > input {
    width: 50%;
    min-height: auto;
    text-align: center;
    margin-top: 1vmin;
    margin-bottom: 1vmin;
}

@-webkit-keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    -webkit-animation-duration: 1.25s;
            animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
            animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.shimmer-text {
    margin: 1vmin 4vmin;
    border-radius: 5px;
    height: 25px;
    width: 75%;
}

.shimmer-img {
    margin: 1vmin 4vmin;
    border-radius: 5px;
    height: 20vh;
    width: 50%;
}

.shimmer-switch {
    margin: 1vmin 4vmin;
    border-radius: 5px;
    height: 36px;
    width: 58px;
}

.shimmer {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
            animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #e8e8e8 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
}

.no-link {
    text-decoration: none;
    color: white;
}

.items-products {
    display: flex;
}

.items-products-qty {
    color: #28AF63;
}

.items-products-separator {
    margin-left: 5px;
    margin-right: 5px;
}

.status-label {
    color: #F7F7F7;
    border-radius: 5px;
    font-size: 10px;
    padding: 2px;
}

.status-orange {
    background-color: #F5992E;
}

.status-yellow {
    background-color: #f1f501;
    color: black;
}

.status-red {
    background-color: #FC332A;
}

.status-green {
    background-color: #28AF63;
}

.confirm-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.confirm-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.confirm-close {
    font-size: 5vmin;
    cursor: pointer;
    align-self: flex-end;
}

.confirm-box {
    padding: 10px;
    border-radius: 10px;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 150px;
    justify-content: space-between;
    box-shadow: 0px 2px 14px #000000;
}

.confirm-button-container {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.confirm-button-no {
    background-color: #FF635C;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 15px;
    padding: .5rem 1rem;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}

.confirm-button-yes {
    background-color: #28AF63;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 15px;
    padding: .5rem 1rem;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}

.confirm-button-no:hover {
    background-color: #612220;
}

.confirm-button-yes:hover {
    background-color: #13613a;
}

.input-style {
    width: 100%;
    background-color: #F7F7F7;
    text-align: center;
    resize: vertical;
}

.input-style:active {
    border: 1px solid #D9D9D9 !important;
}

.input-file {
    height: 100%;
    opacity: 0;
    position: -webkit-sticky;
    position: sticky;
    width: auto;
    z-index: 3;
    cursor: pointer;
}

.input-file-img {
    float: left;
    position: absolute;
    max-width: 42px;
}

.onoffswitch {
    position: relative; width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 36px; padding: 0; line-height: 36px;
    border: 2px solid #E3E3E3; border-radius: 36px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 36px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 22px;
    border: 2px solid #E3E3E3; border-radius: 36px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-page {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-input {
    text-align: center;
    padding: 5px;
    font-size: 16px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -webkit-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    -moz-box-shadow: 0px 2px 7px rgba(66,66,66,.51);
    min-width: 14vmax;
    margin-bottom: 1vmax;
    border-radius: 3px;

}

.login-button {
    background-color: #28AF63;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 20px;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}
.login-button:hover {
    background-color: #2d8a3d;
}

.login-logo {
    height: auto;
    width: 15vmin;
    align-self: center;
}
.navbar-content {
    display: flex;
    justify-content: space-between;
}

.navbar-logo {
    height: 18vmin;
    width: 18vmin;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-link {
    display: flex;
    align-items: center;
}

.nav {
    list-style: none;
    display: flex;
    justify-content: space-around;
    font-family: 'Arvo', serif;
    font-size: 22px;
}

.nav-logo {
    height: 10vmin;
}

.nav-link {
    margin-right: 5vmin;
}

nav {
    position: relative;
    box-shadow: 0px 3px 10px 0px rgba(72,72,72,0.6);
    -webkit-box-shadow: 0px 3px 10px 0px rgba(72,72,72,0.6);
    -moz-box-shadow: 0px 3px 10px 0px rgba(72,72,72,0.6);
    background-color: #f7f7f7;
}

.nav-active {
    border-bottom: 4px solid #28AF63;
}

.nav-notif::after {
    content: "•";
    position: relative;
    top: -5px;
    color: #FF635C;
}

.nav-a {
    color: #1A2828;
    text-decoration: none;
}
.nrecipe-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.nrecipe-header {
    display: flex;
    flex-direction: row;
    margin-left: 4vmax;
}

.nrecipe-form-container {
    display: flex;
    flex-direction: column;
    margin-left: 4vmax;
    margin-right: 4vmax;
}

.nrecipe-form-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nrecipe-form-left {

}

.nrecipe-form-right {
    width: 50%;
    display: flex;
}

.list-container > div {
    list-style: none;
    padding-bottom: 1vmin;
    color: #1A2828;
}

.list-container > div::before {
    content: "• ";
    color: #2d8a3d;
}

.list-container {
    text-align: left;
    height: 50%;
    margin-top: 2vmin;
}

.mid-large {
    width: 55%;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

.form-description-container {
    display: flex;
    flex-direction: column;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 7px #555555;
    border: 1px solid #D9D9D9;
    margin-bottom: 1vmax;
    margin-top: 2vmax;
    padding: 1vmax;
}

.form-description-label {
    align-self: flex-start;
    margin-top: 0;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
    align-items: center;
}

.form-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-img {
    border-radius: 5px;
    margin-top: 2vmin;
    max-width: 20vmin;
}

.input-recipe-label {
    margin: 0;
}

.input-recipe-header {
    margin-left: 2vmin;
    text-align: center;
}

.input-recipe-text {
    resize: none;
    min-height: 10vmax!important;
}

.input-recipe {
    border: 1px solid #DDDDDD;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    border-radius: 5px;
    min-height: 25px;
}

.input-recipe:focus {
    box-shadow: 0 0 5px #28AF63;
    border: 1px solid #28AF63;
}

.button-recipe {
    align-self: flex-end;
    margin-top: 5vmin;
    margin-bottom: 2vmin;
}

.onoffswitch {
    position: relative; width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 25px; padding: 0; line-height: 25px;
    border: 2px solid #E3E3E3; border-radius: 25px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 25px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 33px;
    border: 2px solid #E3E3E3; border-radius: 25px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: #28AF63;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}

.manage {
    width: 100%;
    display: flex;
    margin-top: 3vmax;
    flex-direction: row;
    flex-wrap: wrap;
}

.manage-flex {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vmax;
}

.manage-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

