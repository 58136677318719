.dash-table {
    display: flex;
    justify-content: space-evenly;
}

.dash-table-header {
    background-color: #28AF63;
    display: flex;
    justify-content: space-evenly;
    color: #F7F7F7;
    -webkit-border-top-left-radius: .9vmin;
    -webkit-border-top-right-radius: .9vmin;
    -moz-border-radius-topleft: .9vmin;
    -moz-border-radius-topright: .9vmin;
    border-top-left-radius:.9vmin;
    border-top-right-radius:.9vmin;
}

.dash-table-row {
    background-color: #F7F7F7;
    display: flex;
    justify-content: space-evenly;
    color: black;
    width: 100%;
}

.table {
    margin: 5vmax;
    border-color: #D9D9D9;
}

.dash-table-label {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    width: 100%;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 5 1 0px;
}

.dash-table-cell {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    width: 100%;
    border: 1px solid #D9D9D9 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 5 1 0px;
}

.dash-table-cell-qty {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    width: 100%;
    border: 1px solid #D9D9D9 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 15 1 0px;
}

.dash-table-multiple {
    justify-content: space-around !important;
}

.dash-table-cell-reduce {
    flex: 3 1 0px;
}

.dash-table-cell-expand {
    flex: 8 1 0px;
}

.dash-table-cell-content {
    font-size: .7vw;
}

.table-cell-img > img {
    max-width: 50%;
    max-height: 20vh;
    border-radius: 10px;
}

.dash-table-empty {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    flex: 1 1 0px;
    border: .05vmin solid #D9D9D9;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.cell-edit {
    cursor: pointer;
    width: 60%;
}

.dash-table-reduce {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3 1 0px;
}

.dash-table-quantity {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 15 1 0px;
    width: 100%;
}

.dash-table-expand {
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    font-family: 'Nunito', serif;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 8 1 0px;
    width: 100%;
}

.dash-table-button {
    color: #FD0B00;
    border-radius: 3px;
    border: none;
    background-color: transparent;
    font-family: 'Nunito', serif;
    font-size: 20px;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
}

.dash-table-button:hover {
    color: #9f0900;
}

.dash-table-validate {
    color: #28AF63!important;
}

.dash-table-validate:hover {
    color: #144b25!important;
}

.dash-table-container {
    width: 100%;
    height: 100%;
}

.dash-table-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.promotion-label {
    font-size: .7vw;
    width: 50%;
    line-height: 15px;
    cursor: pointer;
}

.promotion-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
}

.input-money::after {
    content: "€";
    margin-top: 1vmin;
    font-size: smaller;
}

.promotion-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}

.promotion-button-input {
    border-radius: 5px;
    background-color: #28AF63;
    color: #F7F7F7;
    border: none;
    box-shadow: 0px 1px 7px #000000;
    cursor: pointer;
}

.promotion-button-disabled {
    cursor: default;
}

.promotion-display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-top: 2vmin;
}

.promotion-input > input {
    width: 50%;
    min-height: auto;
    text-align: center;
    margin-top: 1vmin;
    margin-bottom: 1vmin;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.shimmer-text {
    margin: 1vmin 4vmin;
    border-radius: 5px;
    height: 25px;
    width: 75%;
}

.shimmer-img {
    margin: 1vmin 4vmin;
    border-radius: 5px;
    height: 20vh;
    width: 50%;
}

.shimmer-switch {
    margin: 1vmin 4vmin;
    border-radius: 5px;
    height: 36px;
    width: 58px;
}

.shimmer {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #e8e8e8 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
}

.no-link {
    text-decoration: none;
    color: white;
}

.items-products {
    display: flex;
}

.items-products-qty {
    color: #28AF63;
}

.items-products-separator {
    margin-left: 5px;
    margin-right: 5px;
}

.status-label {
    color: #F7F7F7;
    border-radius: 5px;
    font-size: 10px;
    padding: 2px;
}

.status-orange {
    background-color: #F5992E;
}

.status-yellow {
    background-color: #f1f501;
    color: black;
}

.status-red {
    background-color: #FC332A;
}

.status-green {
    background-color: #28AF63;
}

.confirm-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.confirm-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.confirm-close {
    font-size: 5vmin;
    cursor: pointer;
    align-self: flex-end;
}

.confirm-box {
    padding: 10px;
    border-radius: 10px;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 150px;
    justify-content: space-between;
    box-shadow: 0px 2px 14px #000000;
}

.confirm-button-container {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.confirm-button-no {
    background-color: #FF635C;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 15px;
    padding: .5rem 1rem;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}

.confirm-button-yes {
    background-color: #28AF63;
    border-radius: 3px;
    border: none;
    color: #F7F7F7;
    font-family: 'Nunito', serif;
    font-size: 15px;
    padding: .5rem 1rem;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0px 2px 14px #000000;
}

.confirm-button-no:hover {
    background-color: #612220;
}

.confirm-button-yes:hover {
    background-color: #13613a;
}
